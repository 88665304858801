import React from "react";

import "./ContactInfo.css";

import { AiOutlineMail, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";
import { IoLocationOutline } from "react-icons/io5";
import { MdPhoneAndroid } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";

const ContactInfo = () => {
  const contacts = [
    {
      icon: <IoLocationOutline />,
      contactTitle: "Visit Us",
      contact1:
        "25 Newbridge Road, Suite 302, Hicksville, New York 11801, United States",
      contact2:
        "126-01 Liberty Avenue, South Richmond Hill Queens, New York 11419, United States",
      contact3: "103-21 98th St, Jamaica, New York 11417",
    },
    {
      icon: <MdPhoneAndroid />,
      contactTitle: "Call Us",
      contact1: "Phone: 516-367-2266",
      contact2: "Fax: 516-367-2266",
    },
    {
      icon: <AiOutlineMail />,
      contactTitle: "Email Us",
      contact1: "info@cottagehomecare.com",
      contact2: "info@cottagehomecare.com",
    },
    {
      icon: <RiUserFollowLine />,
      contactTitle: "Follow Us",
      icons: [<FaFacebookF />, <AiOutlineTwitter />, <ImInstagram />],
    },
  ];

  return (
    <div className="mt-16 mb-8" id="contact-info">
      <div>
        <div
          className="w-[95%] lg:w-[50%] mx-auto text-center"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <h1 className="advertise my-5 text-3xl font-semibold dark:text-gray-100">
            Cottage Home Care Services
          </h1>
          <p className="Poppins dark:text-gray-100">
            {" "}
            Cottage Home Care Services is open to any feedback. Have questions?
            Unsure whether you can be covered by your insurance for Home Care
            Services? Need assessment? We would love to speak to you. Please
            call or email us! Thank you!{" "}
          </p>
        </div>
      </div>

      <div className="w-[95%] lg:w-[85%] mx-auto grid md:grid-cols-2 lg:grid-cols-4 mt-[100px] Poppins gap-5 lg:gap-0">
        <div className=" mr-1 mt-1 md:mt-0 ">
          <div
            className="relative bg-[#EBF8F9] dark:bg-slate-800 py-4 px-5 min-h-[27rem] rounded-md cart "
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            <h5 className="text-gray-700 text-lg text-center mt-14  font-bold berlin dark:text-gray-100">
              Visit Us
            </h5>

            <p className="absolute bg-primary text-white w-20 h-20 rounded-full flex items-center justify-center text-4xl left-[37%] -top-8 logo">
              <IoLocationOutline />
            </p>

            <a
              href="https://www.google.com/maps/dir//25+Newbridge+Rd+Unit+302,+Oyster+Bay,+NY+11801/@40.7680687,-73.5969743,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c280e280b85923:0x6d08773ea58e3908!2m2!1d-73.5269344!2d40.7680899"
              target="_blank"
              className="mt-16 text-sm text-gray-600  dark:text-gray-100 2xl:text-justify block hover:underline hover:text-primary auto-hyphen list-disc"
            >
              25 Newbridge Road, Suite 302, Hicksville, New York 11801, United
              States
            </a>
            <a
              href="https://www.google.com/maps?daddr=126-01+Liberty+Avenue,+South+Richmond+Hill+Queens,+New+York+11419,+United+States"
              target="_blank"
              className="mt-4 text-sm  text-justify dark:text-gray-100 text-gray-600 block hover:text-primary hover:underline auto-hyphen"
            >
              126-01 Liberty Avenue, South Richmond Hill Queens, New York 11419,
              United States
            </a>
            {/* <a
              href="https://www.google.com/maps/dir//103-21+98th+St,+Jamaica,+NY+11417,+USA/@40.6823889,-73.8443419,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c267589d232e07:0x58530cff1202b8d6!2m2!1d-73.841767!2d40.6823849?entry=ttu"
              target="_blank"
              className="mt-4 text-sm text-justify dark:text-gray-100 text-gray-600 auto-hyphen block hover:text-primary hover:underline auto-hyphen"
            >
              103-21 98th St, Jamaica, New York 11417, United States
            </a> */}
            <a
              href="https://www.google.com/maps/dir//130+Washington+Ave,+Albany,+NY+12210,+USA/@42.6548809,-73.795538,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89de0a3aa4c10641:0xaf8dd4ae670e2237!2m2!1d-73.7605185!2d42.6548231?hl=en"
              target="_blank"
              className="mt-4 text-sm text-justify dark:text-gray-100 text-gray-600 block hover:text-primary hover:underline auto-hyphen"
            >
              130 Washington Ave 3rd Floor Unit G, Albany, NY 12210 - (CDPAP
              Services)
            </a>
            <a
              href="https://www.google.com/maps/dir//391+E+149th+St,+Bronx,+NY+10455,+USA/@40.8164095,-73.9232657,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c2f5c8cbeb9cad:0x62ee200e1f45a264!2m2!1d-73.9181158!2d40.8164096?entry=ttu"
              target="_blank"
              className="mt-4 text-sm text-justify dark:text-gray-100 text-gray-600 block hover:text-primary hover:underline auto-hyphen"
            >
              391 East 149th Street, Bronx, New York 10455
            </a>
          </div>
        </div>
        <div className=" mr-1 mt-1 md:mt-0">
          <div
            className="relative bg-[#EBF8F9] dark:bg-slate-800  py-4 px-3 min-h-[27rem] rounded-md  cart"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <h5 className="text-gray-700 text-lg  dark:text-gray-100 text-center mt-14  font-bold Berlin">
              Call Us
            </h5>

            <p className="absolute bg-primary dark:text-gray-100  text-white w-20 h-20 rounded-full flex items-center justify-center text-4xl left-[37%] -top-8 logo">
              <MdPhoneAndroid />
            </p>

            <a
              href="tel:+1516-367-2266"
              className="block mt-16 text-base text-center dark:text-gray-100  text-gray-600 "
            >
              <span className="">Phone:</span>{" "}
              <span className="hover:text-primary">516-367-2266</span>
            </a>
            <a
              href="tel:+1516-367-1067"
              className="block mt-3 text-base text-center dark:text-gray-100  text-gray-600 "
            >
              <span className=""> Fax:</span>{" "}
              <span className="hover:text-primary">516-367-1067</span>
            </a>

            {/* <a href='#' className='text-[#00a6b2b1] flex justify-center items-center text-2xl gap-5 text-gray-600 '>
                            {
                                contact?.icons
                            }
                        </a> */}
          </div>
        </div>
        <div className=" mr-1 mt-1 md:mt-0 cutom-animation">
          <div
            className="relative dark:bg-slate-800 bg-[#EBF8F9]  py-4 px-3 min-h-[27rem] rounded-md cart"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h5 className="text-gray-700 dark:text-gray-100 text-lg text-center mt-14  font-bold Berlin">
              Email Us
            </h5>

            <p className="absolute bg-primary text-white w-20 h-20 rounded-full flex items-center justify-center text-4xl left-[37%] -top-8 logo">
              <AiOutlineMail />
            </p>

            <a
              href="mailto:info@cottagehomecare.com"
              className="block mt-20 text-base text-center dark:text-gray-100 text-gray-600 hover:text-primary"
            >
              info@cottagehomecare.com
            </a>
            {/* <a
              href="mailto:info@cottagehomecare.com"
              className="block mt-3 text-base text-center text-gray-600 hover:text-primary"
            >
              info@cottagehomecare.com
            </a> */}

            {/* <a href='#' className='text-[#00a6b2b1] flex justify-center items-center text-2xl gap-5 text-gray-600 '>
                            {
                                contact?.icons
                            }
                        </a> */}
          </div>
        </div>
        <div className=" mr-1 mt-1 md:mt-0">
          <div
            className="relative dark:bg-slate-800 bg-[#EBF8F9]  py-4 px-3 min-h-[27rem] rounded-md cart"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <h5 className="text-gray-700 text-lg text-center mt-14  font-bold berlin dark:text-gray-100">
              Follow Us
            </h5>

            <p className="absolute bg-primary text-white w-20 h-20 rounded-full flex items-center justify-center text-4xl left-[37%] -top-8 logo">
              <RiUserFollowLine />
            </p>

            <div className="text-[#00a6b2b1] dark:text-gray-100 flex justify-center items-center text-2xl gap-5 mt-20">
              <a
                href="https://www.facebook.com/cottageHC"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://twitter.com/cottagecareny"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineTwitter />
              </a>
              <a
                href="https://www.instagram.com/cottagehcs/"
                target="_blank"
                rel="noreferrer"
              >
                <ImInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;

import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useAdmin from '../Hooks/UseAdmin';
import { AuthContext } from '../Pages/Context/AuthProvider';
import Chat from '../Shared/Chat/Chat';
import AllBlog from './AllBlog';
import BlogBanner from './BlogBanner';
import PageComponent from '../PageComponent/PageComponent';
import useEditor from '../Hooks/useEditor';

const Blog = () => {

const { user } = useContext(AuthContext);
const [isAdmin] = useAdmin(user?.email);
const [isEditor] = useEditor(user?.email);

 

    const url = 'https://cottage-updated-server-v3.vercel.app/blogs'

    const { data: {blogs}= [], isLoading, refetch } = useQuery({
        queryKey: ['blogs',],
        queryFn: async () => {
            const res = await fetch(url);
            const data = await res.json();
            return data;
        }

    })

    
    // console.log(blogs)

    return (
      <div>

        <PageComponent
          title="Blog - Cottage Home Care Services"
          description="Stay updated with the latest insights, tips, and news in the home care industry through our blog. Discover informative articles, expert advice, and stories that highlight the importance of quality home care. Join our community and engage with us through our blog posts."
          keywords="Cottage Home Care team, home care staff, dedicated caregivers, professional healthcare team, compassionate home care providers, team member qualifications, home care experts, meet our team, Cottage Home Care professionals, healthcare staff profiles"
        />
        <BlogBanner></BlogBanner>

        <AllBlog
          blogs={blogs}
          isAdmin={isAdmin}
          isEditor={isEditor}
          isLoading={isLoading}
          refetch={refetch}
        ></AllBlog>
        <Chat></Chat>
      </div>
    );
};

export default Blog;
import React, { useEffect, useState } from "react";
import EasierLife from "../EasierLife/EasierLife";
import HelpSection from "../HelpSection/HelpSection";
import Quality from "../Quality/Quality";
import Chat from "../../../Shared/Chat/Chat";
import Benefits from "../../Benefits/Benefits";
import NewWelcome from "../../NewWelcome/NewWelcome";
import WeAre from "../WeAre/WeAre";
import Welcome from "../Welcome/Welcome";
import WhyChoose from "../WhyChoose/WhyChoose";
import UpdateTestimonial from "./UpdateTestimonial";
import CustomCarousel from "../../../../Components/CustomCarousel/CustomCarousel";
import NewsLetter from "../../../NewsLetter/NewsLetter";
import PageComponent from "../../../PageComponent/PageComponent";
import WebLoading from "../../../WebLoading/WebLoading";
import { IoLocationSharp } from "react-icons/io5";
import "./Home.css";
import MapComponent from "./MapComponent";

const Home = () => {
  const webLoading = sessionStorage.getItem("webLoading");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem("webLoading", true);
      setIsLoading(false);
    }, 1000);
  }, [sessionStorage.getItem("webLoading")]);

  
  
  return (
    <div>
      <PageComponent
        title=" Home - Cottage Home Care Services"
        description="Cottage Home Care Services provides care to seniors in need and has taken on some of the most challenging cases in New York."
        keywords="Cottage, Cottage Home, Cottage Agency, Cottage Home Care, Cottage Home Care Services, Cottage Home Health Care, home care, senior care, New York, challenging cases"
      />

      {isLoading && !webLoading ? (
        <>
          <WebLoading />
        </>
      ) : (
        <>
          <div className="dark:bg-slate-600">
            <CustomCarousel />
            <div className="hidden md:block">
              <Welcome></Welcome>
            </div>
            {/* <NewSwiper/> */}
            <div className="block md:hidden">
              <NewWelcome></NewWelcome>
            </div>

            {/* new testimonial section  start */}

            <UpdateTestimonial />

            {/* new testimonial  section end  */}

            <HelpSection></HelpSection>
            <WeAre></WeAre>
            <Quality></Quality>
            <WhyChoose></WhyChoose>
            <Benefits></Benefits>

            <MapComponent/>

            <EasierLife></EasierLife>
            <NewsLetter />

            <Chat></Chat>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
